@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Georgia', serif;
}

a {
	color: darkred;
	text-decoration: none;
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.logoLink {
  text-decoration: none;
}

.logo {
  height: 50px;
  width: auto;
}

.main {
  flex: 1;
  padding: 2rem;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.footer {
  background-color: #000;
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: auto;
}

