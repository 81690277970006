.formContainer {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formGroup {
  margin-bottom: 15px;
}

.formLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
	box-sizing: border-box;
}

.trueFalseButtons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.formButton {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.formButton:hover {
  background-color: #0056b3;
}

.formError {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.formSuccess {
  color: #28a745;
  font-size: 14px;
  margin-top: 5px;
}

.authButton {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.authButton:hover {
  background-color: #0056b3;
}

/*
.quizList {
  list-style-type: none;
  padding: 0;
}

.quizItem {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
}

.quizImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
*/

.quizList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.quizItem {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  background-color: #000;
  text-align: center;
}

.quizItem:hover {
	transform: scale(1.03);
	transition: all .3s;
}

.quizItem a {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.quizImage {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.quiz-info {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quiz-info h3 {
  margin: 0 0 10px;
}

.quiz-info p {
  margin: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.deleteButton {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.deleteButton:hover {
  background-color: #cc0000;
}

.previewImage {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
}

.prevButton {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prevButton:hover {
  background-color: #0056b3;
}

.nextButton {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nextButton:hover {
  background-color: #0056b3;
}

.endButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.endButton:hover {
  background-color: #0056b3;
}

.quizPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.breadcrumb {
  margin-bottom: 20px;
  font-size: 14px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.quizHeader {
  position: relative;
  color: white;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.quizDetails {
  max-width: 600px;
}

.quizDetails h2 {
  margin-top: 0;
}

.quizContent {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.prevButton, .nextButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.prevButton:disabled, .nextButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.quizResults {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
