.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #000;
}

header h1 {
  margin: 0;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 1rem;
}

main {
  flex: 1;
  padding: 2rem;
}

footer {
  background-color: #f8f9fa;
  padding: 1rem;
  text-align: center;
}

/*
button {
  backface-visibility: hidden;
  background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
  border: 0;
  border-radius: .5rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,Helvetica,sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button:disabled {
  color: #787878;
  cursor: auto;
}

button:not(:disabled):hover {
  transform: scale(1.05);
}

button:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

button:focus {
  outline: 0 solid transparent;
}

button:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

button:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

button:not(:disabled):active {
  transform: translateY(.125rem);
}
*/

