.nav {
  display: flex;
  justify-content: flex-end;
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navLink {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.navLink:hover {
  background-color: #34495e;
}

.logoutButton {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  /*padding: 0.5rem 1rem;*/
  transition: background-color 0.3s;
}

.logoutButton:hover {
  background-color: #34495e;
}
